import "index.css"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// Import all javascript files from src/_components
const componentsContext = require.context("bridgetownComponents", true, /.js$/)
componentsContext.keys().forEach(componentsContext)
console.info("Bridgetown is loaded!")

// Stimulus
const application = Application.start()
const context = require.context("./controllers", true, /.ts$/)
application.load(definitionsFromContext(context))

import hljs from 'highlight.js';
// import 'highlight.js/styles/github.css';
// import 'highlight.js/styles/vs.css';
// import 'highlight.js/styles/mono-blue.css';
// import 'highlight.js/styles/base16/tomorrow.css';
import 'highlight.js/styles/atom-one-light.css';
hljs.registerLanguage('xml', require('highlight.js/lib/languages/xml'));
hljs.registerLanguage('ruby', require('highlight.js/lib/languages/ruby'));
hljs.registerLanguage('json', require('highlight.js/lib/languages/json'));
document.addEventListener('DOMContentLoaded', (event) => {
  document.querySelectorAll('pre code').forEach((el) => {
    hljs.highlightElement(el);
  });
});